<p-messages id="growl" [(value)]="_messageService.messages"></p-messages>

<p-confirmDialog acceptLabel="Sim" width="425" rejectLabel="Não" #cd>
  <footer>
    <button id="nao" type="button" pButton icon="fa-close" label="Não" (click)="cd.reject()"></button>
    <button id="sim" type="button" pButton icon="fa-check" label="Sim" (click)="cd.accept()"></button>
  </footer>
</p-confirmDialog>

<!--
BODY TAG OPTIONS:
=================
Apply one or more of the following classes to get the
desired effect
|---------------------------------------------------------|
| SKINS         | skin-blue (PADRAO)                      |
|               | skin-black (CRIADA)
|               | skin-blue-white (CRIADA)                |
|---------------------------------------------------------|
|LAYOUT OPTIONS | fixed                                   |
|               | layout-boxed                            |
|               | layout-top-nav                          |
|               | sidebar-collapse                        |
|               | sidebar-mini                            |
|---------------------------------------------------------|
-->

<!-- <body class="hold-transition skin-blue sidebar-mini"> -->
<!-- <div class="wrapper" >-->

<!-- Main Header  Cabecaçalho -->
<!-- 
<header class="main-header" *ngIf="authService.isLoggedIn">  
  <a href="#" class="logo">       
    <span class="logo-mini">      
      <img class="responsive-img" src="../assets/img/logo/icon/logo-icon.png">   
    </span>   
    <span class="logo-lg">
      <img class="responsive-img" src="../assets/img/logo/pequeno/logo.png">
    </span>
  </a>
    <nav class="navbar navbar-static-top" role="navigation">    
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button" (click)="toggleMenu()">
        <span class="sr-only">Toggle navigation</span>
      </a>    
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav" style="padding-top: 6px;">
          <li class="dropdown messages-menu">
            <a data-toggle="control-sidebar">
              {{ globalsVariablesService.nomeLoja}}
            </a>
          </li>
          <li class="dropdown messages-menu">
            <a href="https://api.whatsapp.com/send?phone=5562996360547&text=Enviando%20mensagem" target="_blank"
              style="cursor: pointer">
              <i class="fa fa-whatsapp" style="font-size: 21px;"></i></a>
          </li>

          <li *ngIf="globalsVariablesService.isRepresentante" class="dropdown messages-menu">
            <a routerLink="/administrativo/representante">
              <i class="fi-dashboard-opening-email"></i>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.isRepresentante" class="dropdown messages-menu">
            <a routerLink="/administrativo/boleto-cliente">
              <i class="fi-dashboard-dashboard"></i>
            </a>
          </li>

          <li>
            <a (click)="logout()" data-toggle="control-sidebar">
              <i class="fa fa-power-off"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
</header>
 -->
<!-- Left side column. contains the logo and sidebar -->

<aside id="menuLateral" *ngIf="authService.isLoggedIn" [ngClass]="toggleIsOpen ? 'menuLateralAberto' : 'menuLateralFechado'">
  
  <div style="padding: 10px;margin-bottom: 29px;" >
    <a class="sidebar-toggle" data-toggle="push-menu" role="button"  style="font-size: 20px;" (click)="toggleMenu()">
      <span ><i class="fa fa-bars" aria-hidden="true"></i>
      </span>
    </a>
    <a class="logo" style="margin-left: 25px;" *ngIf="toggleIsOpen">
      <span class="logo-lg" >
        <!-- <img class="responsive-img" src="../assets/img/logo/pequeno/logo.png"> -->
        <img class="responsive-img" [src]="secondaryLogo">
      </span>
    </a>

    <!-- <span style="background-color:#071c4d; height: 100px;" >
      <h5 style=" background-color: #071c4d; color: white;">  {{ globalsVariablesService.nomeLoja}} </h5>
    </span>   -->
 </div>

    

  <!-- sidebar: style can be found in sidebar.less -->
  <section>


    <!-- Sidebar user panel (optional) -->
    <div class="subMenuTitulo" (click)="fecharSubMenu()" *ngIf="(subMenuAberto && toggleIsOpen)">
       <span> ⇦ </span> <span> {{subMenuTitulo}}</span>
     </div>       

     <!--
        <div class="pull-left image">
          <img src="{{globalsVariablesService.getUsuarioLogado.caminhoImagem}}" class="img-circle" alt="User Image">
        </div>
        <div class="pull-left info">
          <p><span>Olá</span>,<br>{{globalsVariablesService.getUsuarioLogado.pessoaNomeFantasia}}</p>
          
          <a href="#">
            <i class="fa fa-circle text-success"></i> Online</a>
        </div>
        </div>
      -->

    <!-- search form (Optional)
      <form action="#" method="get" class="menu-search sidebar-form">
        <div class="input-group">        
        </div>
      </form> 
    -->
    <!-- /.search form -->

    <!-- Sidebar Menu -->        
    
    <ul [ngClass]="subMenuAberto ? 'menuFechado' : 'menuAberto'">

      <li class="subMenuItem" (click)=" onClickDashboard();">
        <a  routerLink="/dashboard" >
          <i class="rota fi-dashboard-graphic" style="display: inline;"></i>
          <span *ngIf="!subMenuAberto"> Dashboard </span>
        </a>
      </li>

      <li class="subMenuItem" (click)="abrirSubMenu('cadastros', 'Cadastros', 2)">
        <a>
          <i class="fi-dashboard-rounded-paper-with-text-lines-and-one-folded-corner"></i>
          <span *ngIf="!subMenuAberto">Cadastros</span>
        </a>

        <ul  [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'cadastros'" >
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarLoja">
            <a routerLink="/administrativo/loja">
              <span class="rota icon"> Minha Empresa </span>
            </a>

          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarColaborador" >
            <a class="rota" routerLink="/administrativo/colaborador">
              <span class="rota icon"> Colaborador </span>
            </a>
          </li>

          <!--
            <li class="menuSelecionado">
              <a class="rota" routerLink="/administrativo/contador">
                <span class="rota icon">
                  <i class="rota fa fa-circle-o"></i> Contador </span>
              </a>
            </li>
          -->

          <!--
            <li *ngIf="globalsVariablesService.getPermissoes.visualizarFavorecido" class="menuSelecionado">
              <a class="rota" routerLink="/administrativo/favorecido">
                <span class="rota icon">
                  <i class="rota fa fa-circle-o"></i> Favorecido </span>
              </a>
            </li>
          -->

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFavorecido" >
            <a class="rota" routerLink="/administrativo/cliente">
              <span class="rota icon"> Cliente </span>
            </a>
          </li>


          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTransportadora">
            <a class="rota" routerLink="/administrativo/transportadora">
              <span class="rota icon"> Transportadora </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCategoria" >
            <a class="rota" routerLink="/estoque/categoria">
              <span class="rota icon"> Categoria de Produto </span>
            </a>
          </li>
          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarMarca" -->
          <li class="menuSelecionado">
            <a class="rota" routerLink="/estoque/marca">
              <span class="rota icon"> Marca </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarUnidade">
            <a class="rota" routerLink="/estoque/unidade">
              <span class="rota icon">  Unidade </span>
            </a>
          </li>

          <!--
            <li *ngIf="globalsVariablesService.getPermissoes.visualizarProduto" class="menuSelecionado">
              <a class="rota" routerLink="/estoque/produto">
                <span class="rota icon">
                  <i class="rota fa fa-circle-o"></i> Produto </span>
              </a>
            </li>
          -->

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarProduto">
            <a class="rota" routerLink="/estoque/produto-lite">
              <span class="rota icon"> Produto </span>
            </a>
          </li>

          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarServico" -->
          <li>
            <a class="rota" routerLink="/estoque/servico">
              <span class="rota icon">Serviço </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarProduto">
            <a class="rota" routerLink="/estoque/manutencao-produto">
              <span class="rota icon"> Manutenção Produto </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/administrativo/fornecedor">
              <span class="rota icon"> Fornecedor </span>
            </a>
          </li>


          <!--
            <li *ngIf="globalsVariablesService.getPermissoes.visualizarServico" class="menuSelecionado">
              <a class="rota" routerLink="/estoque/servico">
                <span class="rota icon">
                  <i class="rota fa fa-circle-o"></i> Serviço </span>
              </a>
            </li>
          -->
        </ul>
      </li>



      <li class="subMenuItem" (click)="abrirSubMenu('vendas', 'Vendas', 3)">
        <a>
          <i class="fi-ecommerce-shopping-cart-8"></i>
          <span *ngIf="!subMenuAberto">Vendas</span>
        </a>

        <ul [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'vendas'" >
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPedido">
            <a class="rota" routerLink="/comercial/pedido">
              <span class="rota icon"> Pedido </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPDV">
            <a class="rota" routerLink="/comercial/pdv">
              <span class="rota icon"> PDV </span>
            </a>
          </li>
          <!--
            <li *ngIf="globalsVariablesService.getPermissoes.visualizarPDV" class="menuSelecionado">
              <a class="rota" routerLink="/comercial/comanda">
                <span class="rota icon">
                  <i class="rota fa fa-circle-o"></i> Comanda </span>
              </a>
            </li>
          -->
          <li>
            <a class="rota" routerLink="/comercial/cancelamento-pedido">
              <span class="rota icon"> Cancelamento Pedido</span>
            </a>
          </li>

          <hr/>
        

          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarDevolucaoCliente"  -->
          <li>
            <a class="rota" routerLink="/comercial/devolucao-cliente">
              <span class="rota icon">Devolução Cliente </span>
            </a>
          </li>

        </ul>
      </li>


       <li  class="subMenuItem" (click)="abrirSubMenu('compras', 'Compras',4)">
          <a>
            <i class="fi-ecommerce-trolley"></i>
            <span *ngIf="!subMenuAberto && toggleIsOpen">Compras</span>          
          </a>

          <ul  [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'compras'">
            <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarImportacaoCompraXML"  -->
            <li>
              <a class="rota" routerLink="/comercial/importacao-compra">
                <span class="rota icon">  Importação de XML </span>
              </a>
            </li>
            <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarPedidoCompra" -->
            <li>
              <a class="rota" routerLink="/comercial/pedido-compra">
                <span class="rota icon"> Pedido de Compra </span>
              </a>
            </li>
            <hr/>
            <li>
              <a class="rota" routerLink="/comercial/devolucao-fornecedor">
                <span class="rota icon"> Devolução Fornecedor </span>
              </a>
            </li>

            <!--
            <li *ngIf="globalsVariablesService.getPermissoes.visualizarCancelamentoPedidoCompra">
              <a class="rota" routerLink="/comercial/cancelamento-pedido-compra">
                <span class="rota icon"> Cancelamento de Compra</span>
              </a>
            </li>

            <li *ngIf="globalsVariablesService.getPermissoes.visualizarEntradaCompra">
              <a class="rota" routerLink="/comercial/entrada-compra">
                <span class="rota icon"> Entrada por Compra</span>
              </a>
            </li>

            <li *ngIf="globalsVariablesService.getPermissoes.visualizarCancelamentoEntrada">
              <a class="rota" routerLink="/comercial/cancelamento-entrada">
                <span class="rota icon"> Cancelamento de Entrada </span>
              </a>
            </li>

            <li *ngIf="globalsVariablesService.getPermissoes.visualizarSugestaoCompra">
              <a class="rota" routerLink="/comercial/sugestao-compra">
                <span class="rota icon">  Sugestão de Compra</span>
              </a>
            </li>-->
        </ul>
      </li>

      <!-- 
      <li class="subMenuItem" (click)="abrirSubMenu('devolucoes', 'Devoluções',4)">
        <a>
          <i class="fi-dashboard-speedometer-tool"></i>
          <span *ngIf="!subMenuAberto">Devoluções</span>
        </a>        
        
        <ul [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'devolucoes'">          
          *ngIf="globalsVariablesService.getPermissoes.visualizarDevolucaoFornecedor" 
          
        </ul>
      </li>
    -->



      <li class="subMenuItem" (click)="abrirSubMenu('fiscal', 'Fiscal',5)">
        <a>
          <i class="fi-ecommerce-pen"></i>
          <span *ngIf="!subMenuAberto">Fiscal</span>
        </a>

        <ul [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'fiscal'">
          <li>
            <a class="rota" routerLink="/fiscal/grupo-tributo-produto">
              <span class="rota icon">Grupo Tributo Produto </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarNCM">
            <a class="rota" routerLink="/fiscal/ncm">
              <span class="rota icon"> NCM </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConfiguracaoTributo">
            <a class="rota" routerLink="/fiscal/configuracao-tributo">
              <span class="rota icon">Configuração Tributo </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarOperacao">
            <a class="rota" routerLink="/fiscal/operacao">
              <span class="rota icon"> Operação </span>
            </a>
          </li>

          <hr/>
          <li>
            <a class="rota" routerLink="/comercial/inutilizacao">
              <span class="rota icon"> Inutilização NFCE/NFE</span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/comercial/carta-correcao">
              <span class="rota icon">Carta de Correção </span>
            </a>
          </li>
          <hr/>
          <li>
            <a class="rota" routerLink="/paf-nfce/identificacao-software">
              <span class="rota icon">Dados Software House </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/paf-nfce/gerar-paf-nfce">
              <span class="rota icon">   Gerar Arquivo PAF NFCe </span>
            </a>
          </li>


        </ul>
      </li>

      <!--
      <li class="subMenuItem" (click)="abrirSubMenu('financeiro', 'Financeiro',6)">
        <a>
          <i class="fi-ecommerce-money"></i>
          <span *ngIf="!subMenuAberto">Financeiro</span>          
        </a>
        
        <ul [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'financeiro'">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFormaPagamento">
            <a class="rota" routerLink="/financeiro/forma-pagamento">
              <span class="rota icon"> Forma de Pagamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPrazo">
            <a class="rota" routerLink="/financeiro/prazo">
              <span class="rota icon"> Prazo </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/pdv-pagamento">
              <span class="rota icon">  PDV Pagamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBanco">
            <a class="rota" routerLink="/financeiro/banco"> 
              <span class="rota icon"> Banco </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPlanoContas">
            <a class="rota" routerLink="/financeiro/plano-contas">
              <span class="rota icon"> Plano de Contas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarContaLancamento">
            <a class="rota" routerLink="/financeiro/conta">
              <span class="rota icon">Conta de Lançamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDuplicataReceber">
            <a class="rota" routerLink="/financeiro/conta-receber">
              <span class="rota icon">Duplicatas a Receber </span>
            </a>
          </li>


          <li>
            <a class="rota" routerLink="/financeiro/gerar-remessa">
              <span class="rota icon">
                </i> Gerar Remessa </span>
            </a>
          </li> 

          <li>
            <a class="rota" routerLink="/financeiro/baixar-retorno">
              <span class="rota icon">Baixar Retorno </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarDuplicataPagar ">
            <a class="rota" routerLink="/financeiro/duplicata-pagar">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Duplicatas a Pagar </span>
            </a>
          </li>
  

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarLancamentoFinanceiro">
            <a class="rota" routerLink="/financeiro/lancamentos-financeiros">
              <span class="rota icon">Lançamentos Financeiros </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarEstornoLancamentoFinanceiro"
            class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/estorno-lancamento-financeiro">
              <span class="rota icon"> Estorno Lanç. Financeiro </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/transferencia-contas">
              <span class="rota icon">Transferência Contas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFechamentoConta">
            <a class="rota" routerLink="/financeiro/fechamento-conta">
              <span class="rota icon">Fechamento da Conta </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFaturamento" >
            <a class="rota" routerLink="/financeiro/faturamento">
              <span class="rota icon">Faturamento </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFaturamento">
            <a class="rota" routerLink="/financeiro/faturamento-nota">
              <span class="rota icon">Faturamento Nota </span>
            </a>
          </li>

        </ul>
      </li>
      -->

      <!--    <li *ngIf="globalsVariablesService.setupModel.tipoObjeto == 'V'" class="treeview">
      <li class="treeview" *ngIf="globalsVariablesService.getModulos.visualizarEquipamento">
        <a>
          <i class="fi-dashboard-speedometer-tool"></i>
          <span>Veículos</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFabricanteVeiculo" class="menuSelecionado">
            <a class="rota" routerLink="/veiculos/fabricante">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fabricante </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarModeloVeiculo" class="menuSelecionado">
            <a class="rota" routerLink="/veiculos/modelo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Modelo </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarVeiculo" class="menuSelecionado">
            <a class="rota" routerLink="/veiculos/veiculo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Veículo </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarVeiculo" class="menuSelecionado">
            <a class="rota" routerLink="/veiculos/veiculo-produto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Veículo Produto </span>
            </a>
          </li>
        </ul>
      </li>-->
      <!-- *ngIf="globalsVariablesService.getModulos.visualizarEquipamento 
           *ngIf="globalsVariablesService.setupModel.tipoObjeto == 'E'"
      <li *ngIf="globalsVariablesService.getModulos.visualizarEquipamento" class="treeview">
        <a>
          <i class="fi-ecommerce-trolley"></i>
          <span>Equipamentos</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarModeloEquipamento" class="menuSelecionado">
            <a class="rota" routerLink="/equipamentos/modelo">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Modelo </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarEquipamento" class="menuSelecionado">
            <a class="rota" routerLink="/equipamentos/equipamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Equipamento </span>
            </a>
          </li>
        </ul>
      </li>-->

      <li class="subMenuItem" (click)="abrirSubMenu('seguranca', 'Segurança', 6)">
        <a>
          <i class="fi-ecommerce-padlock"></i>
          <span *ngIf="!subMenuAberto">Segurança</span>
        </a>
        
        <ul [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'seguranca'">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupoLoja" >
            <a class="rota" routerLink="/seguranca/grupo-loja">
              <span class="rota icon">Grupo Loja </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarPermissao">
            <a class="rota" routerLink="/seguranca/permissao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Permissões </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarUsuario">
            <a class="rota" routerLink="/seguranca/usuario">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Usuário </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/seguranca/trocar-senha">
              <span class="rota icon"> Trocar Senha </span>
            </a>
          </li>

        </ul>
      </li>
      <li  class="subMenuItem" (click)="abrirSubMenu('financeiro', 'Financeiro', 7)">
        <a>
          <i class="fi-ecommerce-money"></i>
          <span *ngIf="!subMenuAberto">Financeiro</span>          
        </a>
        
        <ul [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'" *ngIf="subMenuAberto == 'financeiro'">


          <li>
            <a class="rota" routerLink="/financeiro/forma-pagamento">
              <span class="rota icon"> Forma de Pagamento </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/prazo">
              <span class="rota icon"> Prazo </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/pdv-pagamento">
              <span class="rota icon">  PDV Pagamento </span>
            </a>
          </li>


          <li>
            <a class="rota" routerLink="/financeiro/plano-contas">
              <span class="rota icon">Plano de Contas </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/conta">
              <span class="rota icon">Conta de Lançamento </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/contas-receber">
              <span class="rota icon">Contas a Receber </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/contas-pagar">
              <span class="rota icon">Contas a Pagar </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/lancamentos-financeiros">
              <span class="rota icon">Lançamentos Financeiros </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/importar-ofx">
              <span class="rota icon"> Importar OFX </span>
            </a>
          </li>
          <li>
            <a class="rota" routerLink="/financeiro/configuracao-importacao-ofx">
              <span class="rota icon"> Configurar Importar OFX </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/financeiro/fechamento-conta">
              <span class="rota icon">Fechamento da Conta </span>
            </a>
          </li>


        </ul>
      </li>

      <li class="subMenuItem" (click)="abrirSubMenu('relatorios', 'Relatórios', 8)">
        <a>
          <i class="fi-dashboard-rounded-paper-with-text-lines-and-one-folded-corner"></i>
          <span *ngIf="!subMenuAberto">Relatórios</span>
        </a>
        
        <ul  [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'relatorios'">
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioCliente">
            <a class="rota" routerLink="/relatorios/rel-clientes">
              <span class="rota icon">Clientes</span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioProduto">
            <a class="rota" routerLink="/relatorios/rel-produtos">
              <span class="rota icon">Produtos </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/relatorios/rel-inventario">
              <span class="rota icon">   Estoque </span>
            </a>
          </li>
          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioAuditoriaEstoque" -->
          <li>
            <a class="rota" routerLink="/relatorios/kardex">
              <span class="rota icon">Kardex </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioEtiqueta">
            <a class="rota" routerLink="/relatorios/rel-etiqueta-produto">
              <span class="rota icon"> Etiqueta </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioVendas">
            <a class="rota" routerLink="/relatorios/rel-vendas">
              <span class="rota icon">Vendas </span>
            </a>
          </li>
          
          <li>
            <a class="rota" routerLink="/relatorios/rel-vendas-pis-cofins">
              <span class="rota icon"> Pis e Cofins </span>
            </a>
          </li>

          <!-- Depois Colocar na permissão-->
          <li>
            <a class="rota" routerLink="/relatorios/rel-xml-vendas">
              <span class="rota icon">Xml Notas </span>
            </a>
          </li>

          <li *ngIf="false">
            <a class="rota" routerLink="/relatorios/rel-comissoes">
              <span class="rota icon">Relatório Comissões </span>
            </a>
          </li>


          <li *ngIf="false">
            <a class="rota" routerLink="/relatorios/rel-contratos">
              <span class="rota icon"> Relatório Contratos </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioResumoVendas">
            <a class="rota" routerLink="/relatorios/rel-resumo-vendas">
              <span class="rota icon">Resumo de Vendas </span>
            </a>
          </li>



          <li *ngIf="globalsVariablesService.getPermissoes.visualizarMinutaDespacho">
            <a class="rota" routerLink="/relatorios/minuta-despacho">
              <span class="rota icon">Minuta Despacho </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioCompras">
            <a class="rota" routerLink="/relatorios/rel-compra">
              <span class="rota icon">Compras </span>
            </a>
          </li>

          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDuplicatasReceber" -->
          <li>           
            <a class="rota" routerLink="/relatorios/rel-duplicata-receber">
              <span class="rota icon">Contas a Receber </span>
            </a>
          </li>
          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDuplicatasPagar" -->
          <li>
            <a class="rota" routerLink="/relatorios/rel-duplicata-pagar">
              <span class="rota icon">Contas a Pagar </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/relatorios/rel-plano-contas">
              <span class="rota icon"> Plano Contas </span>
            </a>
          </li>

          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioDuplicatasPagar" -->
          <li *ngIf="false">
            <a class="rota" routerLink="/relatorios/rel-lanc-financeiro">
              <span class="rota icon">Lançamentos Financeiro </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioConciliacao">
            <a class="rota" routerLink="/relatorios/rel-conciliacao">
              <span class="rota icon">Conc. Pagar/Receber </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioProdutoEntradaSaida">
            <a class="rota" routerLink="/relatorios/rel-produto-entrada-saida">
              <span class="rota icon">Produtos Entrada/Saida </span>
            </a>
          </li>

          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarRelatorioMovimentacaoPlanoContas" -->
          <li>
            <a class="rota" routerLink="/relatorios/rel-movimentacao-plano-contas">
              <span class="rota icon">Movimentação Plano de Contas</span>
            </a>
          </li>
  
        </ul>
      </li>

      <!-- <li class="subMenuItem" (click)="abrirSubMenu('menuFiscalSC', 'Menu Fiscal', 8)">
        <a>
          <i class="fi-dashboard-rounded-paper-with-text-lines-and-one-folded-corner"></i>
          <span *ngIf="!subMenuAberto">Menu Fiscal</span>
        </a>
        <ul  [ngClass]="subMenuAberto ? 'subMenuAberto' : 'subMenuFechado'"  *ngIf="subMenuAberto == 'menuFiscalSC'">
          <li>
            <a class="rota" routerLink="/paf-nfce/identificacao-software">
              <span class="rota icon">Dados Software House </span>
            </a>
          </li>

          <li>
            <a class="rota" routerLink="/paf-nfce/gerar-paf-nfce">
              <span class="rota icon">   Gerar Arquivo PAF NFCe </span>
            </a>
          </li>
  
        </ul> 
      </li> -->

      <li class="subMenuItem" style="padding-left: 15px;" (click)="logout()">
        <a>
          <i class="fa fa-power-off"></i>
          <span >Sair</span>
        </a>
    </li>   

      <li class="treeview" *ngIf="false">
        <a>
          <i class="fi-creative-settings-2"></i>
          <span>Configurações</span>
          <i class="fa fa-angle-left pull-right"></i>
        </a>
        <ul class="treeview-menu">

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarImportacaoXLS" class="menuSelecionado">
            <a routerLink="/configuracoes/importacao-xls">
              <span class="icon">
                <i class="fa fa-circle-o"></i> Importação XLS</span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSetup" class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/setup">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Setup </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSetup" class="menuSelecionado">
            <a class="rota" routerLink="/configuracoes/conf-nfe">
              <span class="rota icon">
                <i class="rota rota fa fa-circle-o"></i> Configurações NFE </span>
            </a>
          </li>
          <!-- Inicio Cadastros Administrativo-->
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFaixaRenda" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/faixa-renda">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Faixa de Renda </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarProfissao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/profissao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Profissão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConceito" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/conceito">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Conceito </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRamoAtividade" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/ramo-atividade">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Ramo de Atividade </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTipoWeb" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/tipo-web">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Tipo WEB </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTipoTelefone" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/tipo-telefone">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Tipo Telefone </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSituacaoCliente" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/situacao-cliente">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Situação Cliente </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCredenciadorCartao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/credenciadora-cartao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Credenciadora Cartão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBandeiraCartao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/bandeira-cartao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Bandeira Cartão </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarBandeiraCartao" class="menuSelecionado">
            <a class="rota" routerLink="/administrativo/contrato">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Contrato </span>
            </a>
          </li>

          <!-- Fim Cadastros Administrativo-->

          <!-- Estoque Inicio -->
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCustoOperacional" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/custo-operacional">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Custo Operacional </span>
            </a>
          </li>

  
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarFabricante" class="menuSelecionado">
            <!-- Fabricante -->
            <a class="rota" routerLink="/estoque/fabricante">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Fabricante </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarListaPreco" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/lista-preco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Lista de Preço </span>
            </a>
          </li>

          <!-- *ngIf="globalsVariablesService.getPermissoes.visualizarProduto" -->
          <li class="menuSelecionado">
            <a class="rota" routerLink="/estoque/grade-variacao">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grade Variação </span>
            </a>
          </li>

          <li class="menuSelecionado">
            <a class="rota" routerLink="/estoque/grade-produto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grade Produto </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarAtualizaPreco" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/atualiza-preco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Atualiza Preço </span>
            </a>
          </li>


          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGerarBalanco" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/gerar-balanco">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Gerar Balanço </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTransferenciaLojas" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/transferencia-lojas">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Transferência entre Lojas </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConfirmarTransferencia" class="menuSelecionado">
            <a class="rota" routerLink="/estoque/confirmar-transferencia">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Confirmar Transferências </span>
            </a>
          </li>

          <!--Fim estoque -->
          <!-- Comercial-->

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupoDesconto" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/grupo-desconto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo Desconto </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarComissaoDesconto" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/comissao-desconto">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Comissão por Desconto </span>
            </a>
          </li>
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarTabelaFaturamento" class="menuSelecionado">
            <a class="rota" routerLink="/comercial/tabela-faturamento">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Tabela Faturamento </span>
            </a>
          </li>
          <!-- Fim Comercial-->
          <!-- Inicio Fiscal-->
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarGrupoTributoFavorecido" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/grupo-tributo-favorecido">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Grupo Tributo Cliente </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarModeloDocumentoFiscal" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/modelo-documento-fiscal">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Modelo Documento Fiscal </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarCFOP" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/cfop">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> CFOP </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarConvenio" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/convenio">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Convênio </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSituacaoTributaria" class="menuSelecionado">
            <a class="rota" routerLink="/fiscal/situacao-tributaria">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Situação Tributária </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSpedFiscal" class="menuSelecionado">
            <!-- Adicionar novas funcionalidades para tela -->
            <a class="rota" routerLink="/fiscal/sped-icms">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Sped Fiscal </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarSpedPisCofins" class="menuSelecionado">
            <!-- Adicionar novas funcionalidades para tela -->
            <a class="rota">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Sped Pis/Cofins </span>
            </a>
          </li>
          <!-- Fim Fiscal-->

          <!-- Inicio Financeiro-->
          <li *ngIf="globalsVariablesService.getPermissoes.visualizarInstrucaoBancaria" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/instrucao-bancaria">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Instrução Bancária </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarRetornoBancario" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/retorno-bancario">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Retorno Bancário </span>
            </a>
          </li>

          <li *ngIf="globalsVariablesService.getPermissoes.visualizarMotivoDevolucaoCheque" class="menuSelecionado">
            <a class="rota" routerLink="/financeiro/motivo-devolucao-cheque">
              <span class="rota icon">
                <i class="rota fa fa-circle-o"></i> Motivo Dev. de Cheque </span>
            </a>
          </li>
          <!--Fim Financeiro-->
          <li class="subMenuItem" (click)="logout()">
            <a>
              <i class="fa fa-power-off"></i>
              <span >Sair</span>
            </a>
          </li>   
        </ul>
      </li>

   

    </ul>
    <div *ngIf="globalsVariablesService.isRepresentante" style="left: 50px; bottom: 10px; position: fixed;">
      <a routerLink="/administrativo/representante">
        <i class="fa fa-users" style="font-size: 35px;"></i>
      </a>  
    </div>
    
    <div style="left: 5px; bottom: 10px; position: fixed;">
      <a href="https://api.whatsapp.com/send?phone=5547996010999&text=Enviando%20mensagem" target="_blank"
        style="cursor: pointer">
        <i class="fa fa-whatsapp" style="font-size: 35px;"></i>
      </a>
    </div>
    <!-- /.sidebar-menu -->
  </section>
  <!-- /.sidebar -->
</aside>

<!-- Content Wrapper. Contains page content -->
<div id="container-principal" [ngClass]="{'container-mr7': authService.isLoggedIn}">
  <!-- Content Header (Page header) -->
  <!--
          <section class="content-header">
            <h1>
              Page Header
              <small>Optional description</small>
            </h1>
            <ol class="breadcrumb">
              <li><a href="#"><i class="fa fa-dashboard"></i> Level</a></li>
              <li class="active">Here</li>
            </ol>
          </section> -->

  <!-- Main content -->
  <section class="content">

    <router-outlet>

    </router-outlet>

  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->

<!-- Main Footer -
    <footer class="main-footer" *ngIf="authService.isLoggedIn" >

      <div class="pull-right hidden-xs">
        ..
      </div>
      <!-- Default to the left
      <strong>Copyright &copy; 2016 <a href="#">MR7 TI</a>.</strong>todos os direitos reservados.

    </footer>  -->

<!-- /.control-sidebar -->
<!-- Add the sidebar's background. This div must be placed
       immediately after the control sidebar -->

<div class="control-sidebar-bg" *ngIf="authService.isLoggedIn"></div>

<!-- </div> ./wrapper -->

<!-- REQUIRED JS SCRIPTS -->

<!-- jQuery 2.2.0 -->
<!-- Optionally, you can add Slimscroll and FastClick plugins.
     Both of these plugins are recommended to enhance the
     user experience. Slimscroll is required when using the
     fixed layout. -->
<!--</body> -->