import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from "./login/login.component";
import { OnboardingComponent } from './onboarding/onboarding.component';
import { AuthGuard } from "./shared/auth/auth.guard";
import { ViewComponent } from './view/view.component';
 
const routes: Routes = [
  { path: '',                             redirectTo: '/dashboard', pathMatch: 'full'},
  { path: 'login',                        component: LoginComponent , canActivate: [AuthGuard]},
  { path: 'dashboard',                    component: DashboardComponent , canActivate: [AuthGuard]},    
  { path: 'view',                         component:     ViewComponent } ,    
  { path: 'dashboard',                    component:     OnboardingComponent } ,    
  { path: 'administrativo',               loadChildren: () => import('../app/administrativo/administrativo.module').then(m => m.AdministrativoModule),canActivate: [AuthGuard]} ,
  { path: 'estoque',                      loadChildren: () => import('../app/estoque/estoque.module').then(m => m.EstoqueModule),canActivate: [AuthGuard]},  
  { path: 'comercial',                    loadChildren: () => import('../app/comercial/comercial.module').then(m => m.ComercialModule),canActivate: [AuthGuard]},
  { path: 'fiscal',                       loadChildren: () => import('../app/fiscal/fiscal.module').then(m => m.FiscalModule),canActivate: [AuthGuard]},
  { path: 'financeiro',                   loadChildren: () => import('../app/financeiro/financeiro.module').then(m => m.FinanceiroModule),canActivate: [AuthGuard]},
  { path: 'veiculos',                     loadChildren: () => import('../app/veiculos/veiculos.module').then(m => m.VeiculosModule),canActivate: [AuthGuard]},
  { path: 'equipamentos',                 loadChildren: () => import('../app/equipamentos/equipamentos.module').then(m => m.EquipamentosModule)},
  { path: 'seguranca',                    loadChildren: () => import('../app/seguranca/seguranca.module').then(m => m.SegurancaModule),canActivate: [AuthGuard]},
  { path: 'relatorios',                   loadChildren: () => import('../app/relatorios/relatorios.module').then(m => m.RelatoriosModule),canActivate: [AuthGuard]},
  { path: 'paf-nfce',                     loadChildren: () => import('../app/paf-nfce/paf-nfce.module').then(m => m.PAFNFCeModule),canActivate: [AuthGuard]},
  { path: 'configuracoes',                loadChildren: () => import('../app/configuracoes/configuracoes.module').then(m => m.ConfiguracoesModule),canActivate: [AuthGuard]}  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}