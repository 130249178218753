// VARIÁVEIS GLOBAIS QUE PODEM SER ACESSADAS EM TODO PROJETO
import { Injectable } from '@angular/core';
import { Setup } from '../../configuracoes/setup/setup.model';
import { Funcionalidade } from '../../seguranca/permissao/funcionalidade.model';
import { Modulo } from '../../seguranca/permissao/modulo.model';
import { Permissao } from '../../seguranca/permissao/permissao.model';
import { UsuarioLogado } from '../../seguranca/usuario/usuario-logado.model';


@Injectable()
export class GlobalsVariablesService {
    permissao = Object();
    modulos = Object();
    model: Permissao;
    usuarioLogado = Object();
    isPermissao: Boolean = false;
    tipoSistema: string ='L';
    setup: Setup = new Setup();
    qtdeDiasLicencaSistema:number;

    public nomeLoja:string;
    public isRepresentante:boolean=false;
    public idRepresentante:number;

    public LITE_COMPRA:boolean=true;

    public setGlobalsVariables(usuarioLogado: UsuarioLogado) {
        //DADOS DO USUÁRIO
        this.usuarioLogado = usuarioLogado;
        //DADOS DE PERMISSÃO DO USUÁRIO
        this.model = this.usuarioLogado.permissao;
        let modulo: Array<Modulo> = this.model.modulo;
        this.nomeLoja  = this.usuarioLogado.lojaPessoaNomeFantasia;
        this.idRepresentante = this.usuarioLogado.idRepresentante;
        this.qtdeDiasLicencaSistema = this.usuarioLogado.diasLicenca;

        for (var i = 0; i < modulo.length; i++) {
            let funcionalidade: Array<Funcionalidade> = modulo[i].funcionalidade;

            if (modulo[i].nome == "cadastros") {
                this.modulos.visualizarCadastros = modulo[i].visualizar;
            }

            if (modulo[i].nome == "vendas") {
                this.modulos.visualizarVendas = modulo[i].visualizar;
            }
            
            if (modulo[i].nome == "compras") {
                this.modulos.visualizarCompras = modulo[i].visualizar;
            }

            if (modulo[i].nome == "devolucoes") {
                this.modulos.visualizarDevolucoes = modulo[i].visualizar;
            }

            if (modulo[i].nome == "fiscal") {
                this.modulos.visualizarFiscal = modulo[i].visualizar;
            }

            if (modulo[i].nome == "financeiro") {
                this.modulos.visualizarFinanceiro = modulo[i].visualizar;
            }

            if (modulo[i].nome == "seguranca") {
                this.modulos.visualizarSeguranca = modulo[i].visualizar;
            }

            if (modulo[i].nome == "equipamento") {
                this.modulos.visualizarEquipamento = modulo[i].visualizar;
            }

            if (modulo[i].nome == "relatorio") {
                this.modulos.visualizarRelatorios = modulo[i].visualizar;
            }

            if (modulo[i].nome == "configuracoes") {
                this.modulos.visualizarConfiguracoes = modulo[i].visualizar;
            }


            for (var j = 0; j < funcionalidade.length; j++) {
                let funcionalidadeNome: String = funcionalidade[j].nome;

                // -----------------------------------------   Administrativo -------------------------------------------//
                if (funcionalidadeNome == "dashboard") {
                  this.permissao.visualizarDashboard = funcionalidade[j].visualizar;
                  this.permissao.alterarDashboard = funcionalidade[j].alterar;
                  this.permissao.salvarDashboard = funcionalidade[j].incluir;
                  this.permissao.excluirDashboard = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "loja") {
                    this.permissao.visualizarLoja = funcionalidade[j].visualizar;
                    this.permissao.alterarLoja = funcionalidade[j].alterar;
                    this.permissao.salvarLoja = funcionalidade[j].incluir;
                    this.permissao.excluirLoja = funcionalidade[j].excluir;

                }

                if (funcionalidadeNome == "colaborador") {
                    this.permissao.visualizarColaborador = funcionalidade[j].visualizar;
                    this.permissao.alterarColaborador = funcionalidade[j].alterar;
                    this.permissao.salvarColaborador = funcionalidade[j].incluir;
                    this.permissao.excluirColaborador = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "favorecido") {
                    this.permissao.visualizarFavorecido = funcionalidade[j].visualizar;
                    this.permissao.alterarFavorecido = funcionalidade[j].alterar;
                    this.permissao.salvarFavorecido = funcionalidade[j].incluir;
                    this.permissao.excluirFavorecido = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "faixaRenda") {
                    this.permissao.visualizarFaixaRenda = funcionalidade[j].visualizar;
                    this.permissao.salvarFaixaRenda = funcionalidade[j].incluir;
                    this.permissao.alterarFaixaRenda = funcionalidade[j].alterar;
                    this.permissao.excluirFaixaRenda = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "transportadora") {
                    this.permissao.visualizarTransportadora = funcionalidade[j].visualizar;
                    this.permissao.salvarTransportadora = funcionalidade[j].incluir;
                    this.permissao.alterarTransportadora = funcionalidade[j].alterar;
                    this.permissao.excluirTransportadora = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "profissao") {
                    this.permissao.visualizarProfissao = funcionalidade[j].visualizar;
                    this.permissao.salvarProfissao = funcionalidade[j].incluir;
                    this.permissao.alterarProfissao = funcionalidade[j].alterar;
                    this.permissao.excluirProfissao = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "conceito") {
                    this.permissao.visualizarConceito = funcionalidade[j].visualizar;
                    this.permissao.alterarConceito = funcionalidade[j].alterar;
                    this.permissao.salvarConceito = funcionalidade[j].incluir;
                    this.permissao.excluirConceito = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "ramoAtividade") {
                    this.permissao.visualizarRamoAtividade = funcionalidade[j].visualizar;
                    this.permissao.salvarRamoAtividade = funcionalidade[j].incluir;
                    this.permissao.alterarRamoAtividade = funcionalidade[j].alterar;
                    this.permissao.excluirRamoAtividade = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "tipoWeb") {
                    this.permissao.visualizarTipoWeb = funcionalidade[j].visualizar;
                    this.permissao.salvarTipoWeb = funcionalidade[j].incluir;
                    this.permissao.alterarTipoWeb = funcionalidade[j].alterar;
                    this.permissao.excluirTipoWeb = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "tipoTelefone") {
                    this.permissao.visualizarTipoTelefone = funcionalidade[j].visualizar;
                    this.permissao.salvarTipoTelefone = funcionalidade[j].incluir;
                    this.permissao.alterarTipoTelefone = funcionalidade[j].alterar;
                    this.permissao.excluirTipoTelefone = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "situacaoCliente") {
                    this.permissao.visualizarSituacaoCliente = funcionalidade[j].visualizar;
                    this.permissao.salvarSituacaoCliente = funcionalidade[j].incluir;
                    this.permissao.alterarSituacaoCliente = funcionalidade[j].alterar;
                    this.permissao.excluirSituacaoCliente = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "credenciadoraCartao") {
                    this.permissao.visualizarCredenciadorCartao = funcionalidade[j].visualizar;
                    this.permissao.salvarCredenciadorCartao = funcionalidade[j].incluir;
                    this.permissao.alterarCredenciadorCartao = funcionalidade[j].alterar;
                    this.permissao.excluirCredenciadorCartao = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "bandeiraCartao") {
                    this.permissao.visualizarBandeiraCartao = funcionalidade[j].visualizar;
                    this.permissao.salvarBandeiraCartao = funcionalidade[j].incluir;
                    this.permissao.alterarBandeiraCartao = funcionalidade[j].alterar;
                    this.permissao.excluirBandeiraCartao = funcionalidade[j].excluir;
                }

                // ------------------------------------------Estoque ----------------------------------------------------//

                if (funcionalidadeNome == "categoria") {
                    this.permissao.visualizarCategoria = funcionalidade[j].visualizar;
                    this.permissao.salvarCategoria = funcionalidade[j].incluir;
                    this.permissao.alterarCategoria = funcionalidade[j].alterar;
                    this.permissao.excluirCategoria = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "marca") {
                    this.permissao.visualizarMarca = funcionalidade[j].visualizar;
                    this.permissao.salvarMarca = funcionalidade[j].incluir;
                    this.permissao.alterarMarca = funcionalidade[j].alterar;
                    this.permissao.excluirMarca = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "fabricante") {
                    this.permissao.visualizarFabricante = funcionalidade[j].visualizar;
                    this.permissao.salvarFabricante = funcionalidade[j].incluir;
                    this.permissao.alterarFabricante = funcionalidade[j].alterar;
                    this.permissao.excluirFabricante = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "unidade") {
                    this.permissao.visualizarUnidade = funcionalidade[j].visualizar;
                    this.permissao.salvarUnidade = funcionalidade[j].incluir;
                    this.permissao.alterarUnidade = funcionalidade[j].alterar;
                    this.permissao.excluirUnidade = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "custoOperacional") {
                    this.permissao.visualizarCustoOperacional = funcionalidade[j].visualizar;
                    this.permissao.salvarCustoOperacional = funcionalidade[j].incluir;
                    this.permissao.alterarCustoOperacional = funcionalidade[j].alterar;
                    this.permissao.excluirCustoOperacional = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "listaPreco") {
                    this.permissao.visualizarListaPreco = funcionalidade[j].visualizar;
                    this.permissao.salvarListaPreco = funcionalidade[j].incluir;
                    this.permissao.alterarListaPreco = funcionalidade[j].alterar;
                    this.permissao.excluirListaPreco = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "produto") {
                    this.permissao.visualizarProduto = funcionalidade[j].visualizar;
                    this.permissao.salvarProduto = funcionalidade[j].incluir;
                    this.permissao.alterarProduto = funcionalidade[j].alterar;
                    this.permissao.excluirProduto = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "servico") {
                    this.permissao.visualizarServico = funcionalidade[j].visualizar;
                    this.permissao.salvarServico = funcionalidade[j].incluir;
                    this.permissao.alterarServico = funcionalidade[j].alterar;
                    this.permissao.excluirServico = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "atualizaPreco") {
                    this.permissao.visualizarAtualizaPreco = funcionalidade[j].visualizar;
                    this.permissao.salvarAtualizaPreco = funcionalidade[j].incluir;
                    this.permissao.alterarAtualizaPreco = funcionalidade[j].alterar;
                    this.permissao.excluirAtualizaPreco = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "gerarBalanco") {
                    this.permissao.visualizarGerarBalanco = funcionalidade[j].visualizar;
                    this.permissao.salvarGerarBalanco = funcionalidade[j].incluir;
                    this.permissao.alterarGerarBalanco = funcionalidade[j].alterar;
                    this.permissao.excluirGerarBalanco = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "transferenciaLojas") {
                    this.permissao.visualizarTransferenciaLojas = funcionalidade[j].visualizar;
                    this.permissao.salvarTransferenciaLojas = funcionalidade[j].incluir;
                    this.permissao.alterarTransferenciaLojas = funcionalidade[j].alterar;
                    this.permissao.excluirTransferenciaLojas = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "confirmarTransferencia") {
                    this.permissao.visualizarConfirmarTransferencia = funcionalidade[j].visualizar;
                    this.permissao.salvarConfirmarTransferencia = funcionalidade[j].incluir;
                    this.permissao.alterarConfirmarTransferencia = funcionalidade[j].alterar;
                    this.permissao.excluirConfirmarTransferencia = funcionalidade[j].excluir;
                }

                // -----------------------------------------   Vendas  -------------------------------------------//
                if (funcionalidadeNome == "pedido") {
                    this.permissao.visualizarPedido = funcionalidade[j].visualizar;
                    this.permissao.salvarPedido = funcionalidade[j].incluir;
                    this.permissao.alterarPedido = funcionalidade[j].alterar;
                    this.permissao.excluirPedido = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "pdv") {
                    this.permissao.visualizarPDV = funcionalidade[j].visualizar;
                    this.permissao.salvarPDV = funcionalidade[j].incluir;
                    this.permissao.alterarPDV = funcionalidade[j].alterar;
                    this.permissao.excluirPDV = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "excluirItemPDV") {
                    this.permissao.visualizarExcluirItemPDV = funcionalidade[j].visualizar;
                    this.permissao.salvarExcluirItemPDV = funcionalidade[j].incluir;
                    this.permissao.alterarExcluirItemPDV = funcionalidade[j].alterar;
                    this.permissao.excluirExcluirItemPDV = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "cancelamentoPedido") {
                    this.permissao.visualizarCancelamentoPedido = funcionalidade[j].visualizar;
                    this.permissao.salvarCancelamentoPedido = funcionalidade[j].incluir;
                    this.permissao.alterarCancelamentoPedido = funcionalidade[j].alterar;
                    this.permissao.excluirCancelamentoPedido = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "grupoDesconto") {
                    this.permissao.visualizarGrupoDesconto = funcionalidade[j].visualizar;
                    this.permissao.salvarGrupoDesconto = funcionalidade[j].incluir;
                    this.permissao.alterarGrupoDesconto = funcionalidade[j].alterar;
                    this.permissao.excluirGrupoDesconto = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "comissaoDesconto") {
                    this.permissao.visualizarComissaoDesconto = funcionalidade[j].visualizar;
                    this.permissao.salvarComissaoDesconto = funcionalidade[j].incluir;
                    this.permissao.alterarComissaoDesconto = funcionalidade[j].alterar;
                    this.permissao.excluirComissaoDesconto = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "tabelaFaturamento") {
                    this.permissao.visualizarTabelaFaturamento = funcionalidade[j].visualizar;
                    this.permissao.salvarTabelaFaturamento = funcionalidade[j].incluir;
                    this.permissao.alterarTabelaFaturamento = funcionalidade[j].alterar;
                    this.permissao.excluirTabelaFaturamento = funcionalidade[j].excluir;
                }

                // -----------------------------------------   Compras  -------------------------------------------//

                if (funcionalidadeNome == "pedidoCompra") {
                    this.permissao.visualizarPedidoCompra = funcionalidade[j].visualizar;
                    this.permissao.salvarPedidoCompra = funcionalidade[j].incluir;
                    this.permissao.alterarPedidoCompra = funcionalidade[j].alterar;
                    this.permissao.excluirPedidoCompra = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "cancelamentoCompra") {
                    this.permissao.visualizarCancelamentoPedidoCompra = funcionalidade[j].visualizar;
                    this.permissao.salvarCancelamentoPedidoCompra = funcionalidade[j].incluir;
                    this.permissao.alterarCancelamentoPedidoCompra = funcionalidade[j].alterar;
                    this.permissao.excluirCancelamentoPedidoCompra = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "cancelamentoEntrada") {
                    this.permissao.visualizarCancelamentoEntrada = funcionalidade[j].visualizar;
                    this.permissao.salvarCancelamentoEntrada = funcionalidade[j].incluir;
                    this.permissao.alterarCancelamentoEntrada = funcionalidade[j].alterar;
                    this.permissao.excluirCancelamentoEntrada = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "entradaCompra") {
                    this.permissao.visualizarEntradaCompra = funcionalidade[j].visualizar;
                    this.permissao.salvarEntradaCompra = funcionalidade[j].incluir;
                    this.permissao.alterarEntradaCompra = funcionalidade[j].alterar;
                    this.permissao.excluirEntradaCompra = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "importacaoCompraXML") {
                    this.permissao.visualizarImportacaoCompraXML = funcionalidade[j].visualizar;
                    this.permissao.salvarImportacaoCompraXML = funcionalidade[j].incluir;
                    this.permissao.alterarImportacaoCompraXML = funcionalidade[j].alterar;
                    this.permissao.excluirImportacaoCompraXML = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "sugestaoCompra") {
                    this.permissao.visualizarSugestaoCompra = funcionalidade[j].visualizar;
                    this.permissao.salvarSugestaoCompra = funcionalidade[j].incluir;
                    this.permissao.alterarSugestaoCompra = funcionalidade[j].alterar;
                    this.permissao.excluirSugestaoCompra = funcionalidade[j].excluir;
                }

                // -----------------------------------------   Devoluções  -------------------------------------------//

                if (funcionalidadeNome == "devolucaoCliente") {
                    this.permissao.visualizarDevolucaoCliente = funcionalidade[j].visualizar;
                    this.permissao.salvarDevolucaoCliente = funcionalidade[j].incluir;
                    this.permissao.alterarDevolucaoCliente = funcionalidade[j].alterar;
                    this.permissao.excluirDevolucaoCliente = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "devolucaoFornecedor") {
                    this.permissao.visualizarDevolucaoFornecedor = funcionalidade[j].visualizar;
                    this.permissao.salvarDevolucaoFornecedor = funcionalidade[j].incluir;
                    this.permissao.alterarDevolucaoFornecedor = funcionalidade[j].alterar;
                    this.permissao.excluirDevolucaoFornecedor = funcionalidade[j].excluir;
                }

                // -----------------------------------------   Fiscal  -------------------------------------------//
                if (funcionalidadeNome == "grupoTributoProduto") {
                    this.permissao.visualizarGrupoTributoProduto = funcionalidade[j].visualizar;
                    this.permissao.salvarGrupoTributoProduto = funcionalidade[j].incluir;
                    this.permissao.alterarGrupoTributoProduto = funcionalidade[j].alterar;
                    this.permissao.excluirGrupoTributoProduto = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "grupoTributoFavorecido") {
                    this.permissao.visualizarGrupoTributoFavorecido = funcionalidade[j].visualizar;
                    this.permissao.salvarGrupoTributoFavorecido = funcionalidade[j].incluir;
                    this.permissao.alterarGrupoTributoFavorecido = funcionalidade[j].alterar;
                    this.permissao.excluirGrupoTributoFavorecido = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "ncm") {
                    this.permissao.visualizarNCM = funcionalidade[j].visualizar;
                    this.permissao.salvarNCM = funcionalidade[j].incluir;
                    this.permissao.alterarNCM = funcionalidade[j].alterar;
                    this.permissao.excluirNCM = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "modeloDocumentoFiscal") {
                    this.permissao.visualizarModeloDocumentoFiscal = funcionalidade[j].visualizar;
                    this.permissao.salvarModeloDocumentoFiscal = funcionalidade[j].incluir;
                    this.permissao.alterarModeloDocumentoFiscal = funcionalidade[j].alterar;
                    this.permissao.excluirModeloDocumentoFiscal = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "cfop") {
                    this.permissao.visualizarCFOP = funcionalidade[j].visualizar;
                    this.permissao.salvarCFOP = funcionalidade[j].incluir;
                    this.permissao.alterarCFOP = funcionalidade[j].alterar;
                    this.permissao.excluirCFOP = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "convenio") {
                    this.permissao.visualizarConvenio = funcionalidade[j].visualizar;
                    this.permissao.salvarConvenio = funcionalidade[j].incluir;
                    this.permissao.alterarConvenio = funcionalidade[j].alterar;
                    this.permissao.excluirConvenio = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "situacaoTributaria") {
                    this.permissao.visualizarSituacaoTributaria = funcionalidade[j].visualizar;
                    this.permissao.salvarSituacaoTributaria = funcionalidade[j].incluir;
                    this.permissao.alterarSituacaoTributaria = funcionalidade[j].alterar;
                    this.permissao.excluirSituacaoTributaria = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "configuracaoTributo") {
                    this.permissao.visualizarConfiguracaoTributo = funcionalidade[j].visualizar;
                    this.permissao.salvarConfiguracaoTributo = funcionalidade[j].incluir;
                    this.permissao.alterarConfiguracaoTributo = funcionalidade[j].alterar;
                    this.permissao.excluirConfiguracaoTributo = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "operacao") {
                    this.permissao.visualizarOperacao = funcionalidade[j].visualizar;
                    this.permissao.salvarOperacao = funcionalidade[j].incluir;
                    this.permissao.alterarOperacao = funcionalidade[j].alterar;
                    this.permissao.excluirOperacao = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "spedFiscal") {
                    this.permissao.visualizarSpedFiscal = funcionalidade[j].visualizar;
                    this.permissao.salvarSpedFiscal = funcionalidade[j].incluir;
                    this.permissao.alterarSpedFiscal = funcionalidade[j].alterar;
                    this.permissao.excluirSpedFiscal = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "spedPisCofins") {
                    this.permissao.visualizarSpedPisCofins = funcionalidade[j].visualizar;
                    this.permissao.salvarSpedPisCofins = funcionalidade[j].incluir;
                    this.permissao.alterarSpedPisCofins = funcionalidade[j].alterar;
                    this.permissao.excluirSpedPisCofins = funcionalidade[j].excluir;
                }

                // ------------------------------------------Financeiro ----------------------------------------------------//


                if (funcionalidadeNome == "planoContas") {
                    this.permissao.visualizarPlanoContas = funcionalidade[j].visualizar;
                    this.permissao.salvarPlanoContas = funcionalidade[j].incluir;
                    this.permissao.alterarPlanoContas = funcionalidade[j].alterar;
                    this.permissao.excluirPlanoContas = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "contaLancamento") {
                    this.permissao.visualizarContaLancamento = funcionalidade[j].visualizar;
                    this.permissao.salvarContaLancamento = funcionalidade[j].incluir;
                    this.permissao.alterarContaLancamento = funcionalidade[j].alterar;
                    this.permissao.excluirContaLancamento = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "banco") {
                    this.permissao.visualizarBanco = funcionalidade[j].visualizar;
                    this.permissao.salvarBanco = funcionalidade[j].incluir;
                    this.permissao.alterarBanco = funcionalidade[j].alterar;
                    this.permissao.excluirBanco = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "instrucaoBancaria") {
                    this.permissao.visualizarInstrucaoBancaria = funcionalidade[j].visualizar;
                    this.permissao.salvarInstrucaoBancaria = funcionalidade[j].incluir;
                    this.permissao.alterarInstrucaoBancaria = funcionalidade[j].alterar;
                    this.permissao.excluirInstrucaoBancaria = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "retornoBancario") {
                    this.permissao.visualizarRetornoBancario = funcionalidade[j].visualizar;
                    this.permissao.salvarRetornoBancario = funcionalidade[j].incluir;
                    this.permissao.alterarRetornoBancario = funcionalidade[j].alterar;
                    this.permissao.excluirRetornoBancario = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "motivoDevolucaoCheque") {
                    this.permissao.visualizarMotivoDevolucaoCheque = funcionalidade[j].visualizar;
                    this.permissao.salvarMotivoDevolucaoCheque = funcionalidade[j].incluir;
                    this.permissao.alterarMotivoDevolucaoCheque = funcionalidade[j].alterar;
                    this.permissao.excluirMotivoDevolucaoCheque = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "prazo") {
                    this.permissao.visualizarPrazo = funcionalidade[j].visualizar;
                    this.permissao.salvarPrazo = funcionalidade[j].incluir;
                    this.permissao.alterarPrazo = funcionalidade[j].alterar;
                    this.permissao.excluirPrazo = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "formaPagamento") {
                    this.permissao.visualizarFormaPagamento = funcionalidade[j].visualizar;
                    this.permissao.salvarFormaPagamento = funcionalidade[j].incluir;
                    this.permissao.alterarFormaPagamento = funcionalidade[j].alterar;
                    this.permissao.excluirFormaPagamento = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "lancamentoFinanceiro") {
                    this.permissao.visualizarLancamentoFinanceiro = funcionalidade[j].visualizar;
                    this.permissao.salvarLancamentoFinanceiro = funcionalidade[j].incluir;
                    this.permissao.alterarLancamentoFinanceiro = funcionalidade[j].alterar;
                    this.permissao.excluirLancamentoFinanceiro = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "estornoLancamentoFinanceiro") {
                    this.permissao.visualizarEstornoLancamentoFinanceiro = funcionalidade[j].visualizar;
                    this.permissao.salvarEstornoLancamentoFinanceiro = funcionalidade[j].incluir;
                    this.permissao.alterarEstornoLancamentoFinanceiro = funcionalidade[j].alterar;
                    this.permissao.excluirEstornoLancamentoFinanceiro = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "duplicataReceber") {
                    this.permissao.visualizarDuplicataReceber = funcionalidade[j].visualizar;
                    this.permissao.salvarDuplicataReceber = funcionalidade[j].incluir;
                    this.permissao.alterarDuplicataReceber = funcionalidade[j].alterar;
                    this.permissao.excluirDuplicataReceber = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "baixaParcelaReceber") {
                    this.permissao.visualizarBaixaParcelaReceber = funcionalidade[j].visualizar;
                    this.permissao.salvarBaixaParcelaReceber = funcionalidade[j].incluir;
                    this.permissao.alterarBaixaParcelaReceber = funcionalidade[j].alterar;
                    this.permissao.excluirBaixaParcelaReceber = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "faturamento") {
                    this.permissao.visualizarFaturamento = funcionalidade[j].visualizar;
                    this.permissao.salvarFaturamento = funcionalidade[j].incluir;
                    this.permissao.alterarFaturamento = funcionalidade[j].alterar;
                    this.permissao.excluirFaturamento = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "duplicataPagar") {
                    this.permissao.visualizarDuplicataPagar = funcionalidade[j].visualizar;
                    this.permissao.salvarDuplicataPagar = funcionalidade[j].incluir;
                    this.permissao.alterarDuplicataPagar = funcionalidade[j].alterar;
                    this.permissao.excluirDuplicataPagar = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "baixaParcelaPagar") {
                    this.permissao.visualizarBaixaParcelaPagar = funcionalidade[j].visualizar;
                    this.permissao.salvarBaixaParcelaPagar = funcionalidade[j].incluir;
                    this.permissao.alterarBaixaParcelaPagar = funcionalidade[j].alterar;
                    this.permissao.excluirBaixaParcelaPagar = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "fechamentoConta") {
                    this.permissao.visualizarFechamentoConta = funcionalidade[j].visualizar;
                    this.permissao.salvarFechamentoConta = funcionalidade[j].incluir;
                    this.permissao.alterarFechamentoConta = funcionalidade[j].alterar;
                    this.permissao.excluirFechamentoConta = funcionalidade[j].excluir;
                }

                // ------------------------------------------Segurança ----------------------------------------------------//

                if (funcionalidadeNome == "permissoes") {
                    this.permissao.visualizarPermissao = funcionalidade[j].visualizar;
                    this.permissao.salvarPermissao = funcionalidade[j].incluir;
                    this.permissao.alterarPermissao = funcionalidade[j].alterar;
                    this.permissao.excluirPermissao = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "usuario") {
                    this.permissao.visualizarUsuario = funcionalidade[j].visualizar;
                    this.permissao.salvarUsuario = funcionalidade[j].incluir;
                    this.permissao.alterarUsuario = funcionalidade[j].alterar;
                    this.permissao.excluirUsuario = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "grupoLoja") {
                    this.permissao.visualizarGrupoLoja = funcionalidade[j].visualizar;
                    this.permissao.salvarGrupoLoja = funcionalidade[j].incluir;
                    this.permissao.alterarGrupoLoja = funcionalidade[j].alterar;
                    this.permissao.excluirGrupoLoja = funcionalidade[j].excluir;
                }

                // -------------------------------------------- Equipamento/Veiculos ----------------------------------------------//
                if (funcionalidadeNome == "telaEquipamento") {
                    this.permissao.visualizarEquipamento = funcionalidade[j].visualizar;
                    this.permissao.salvarEquipamento = funcionalidade[j].incluir;
                    this.permissao.alterarEquipamento = funcionalidade[j].alterar;
                    this.permissao.excluirEquipamento = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "modeloEquipamento") {
                    this.permissao.visualizarModeloEquipamento = funcionalidade[j].visualizar;
                    this.permissao.salvarModeloEquipamento = funcionalidade[j].incluir;
                    this.permissao.alterarModeloEquipamento = funcionalidade[j].alterar;
                    this.permissao.excluirModeloEquipamento = funcionalidade[j].excluir;
                }

                // ------------------------------------------- Veiculos ----------------------------------------------//
                if (funcionalidadeNome == "telaVeiculo") {
                    this.permissao.visualizarVeiculo = funcionalidade[j].visualizar;
                    this.permissao.salvarVeiculo = funcionalidade[j].incluir;
                    this.permissao.alterarVeiculo = funcionalidade[j].alterar;
                    this.permissao.excluirVeiculo = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "fabricanteVeiculo") {
                    this.permissao.visualizarFabricanteVeiculo = funcionalidade[j].visualizar;
                    this.permissao.salvarFabricanteVeiculo = funcionalidade[j].incluir;
                    this.permissao.alterarFabricanteVeiculo = funcionalidade[j].alterar;
                    this.permissao.excluirFabricanteVeiculo = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "modeloVeiculo") {
                    this.permissao.visualizarModeloVeiculo = funcionalidade[j].visualizar;
                    this.permissao.salvarModeloVeiculo = funcionalidade[j].incluir;
                    this.permissao.alterarModeloVeiculo = funcionalidade[j].alterar;
                    this.permissao.excluirModeloVeiculo = funcionalidade[j].excluir;
                }

                // -------------------------------------------- Relatorios ----------------------------------------------//
                if (funcionalidadeNome == "relatorioCliente") {
                    this.permissao.visualizarRelatorioCliente = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioCliente = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioCliente = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioCliente = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioProduto") {
                    this.permissao.visualizarRelatorioProduto = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioProduto = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioProduto = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioProduto = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioVendas") {
                    this.permissao.visualizarRelatorioVendas = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioVendas = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioVendas = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioVendas = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioVendasPisCofins") {
                    this.permissao.visualizarRelatorioVendasPisCofins = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioVendasPisCofins = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioVendasPisCofins = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioVendasPisCofins = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "minutaDespacho") {
                    this.permissao.visualizarMinutaDespacho = funcionalidade[j].visualizar;
                    this.permissao.salvarMinutaDespacho = funcionalidade[j].incluir;
                    this.permissao.alterarMinutaDespacho = funcionalidade[j].alterar;
                    this.permissao.excluirMinutaDespacho = funcionalidade[j].excluir;
                }


                if (funcionalidadeNome == "relatorioResumoVendas") {
                    this.permissao.visualizarRelatorioResumoVendas = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioResumoVendas = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioResumoVendas = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioResumoVendas = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioCompras") {
                    this.permissao.visualizarRelatorioCompras = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioCompras = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioCompras = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioCompras = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioAuditoriaEstoque") {
                    this.permissao.visualizarRelatorioAuditoriaEstoque = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioAuditoriaEstoque = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioAuditoriaEstoque = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioAuditoriaEstoque = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioEtiqueta") {
                    this.permissao.visualizarRelatorioEtiqueta = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioEtiqueta = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioEtiqueta = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioEtiqueta = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioDuplicatasReceber") {
                    this.permissao.visualizarRelatorioDuplicatasReceber = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioDuplicatasReceber = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioDuplicatasReceber = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioDuplicatasReceber = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioDuplicatasPagar") {
                    this.permissao.visualizarRelatorioDuplicatasPagar = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioDuplicatasPagar = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioDuplicatasPagar = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioDuplicatasPagar = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioPlanoContas") {
                    this.permissao.visualizarRelatorioPlanoContas = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioPlanoContas = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioPlanoContas = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioPlanoContas = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioMovimentacaoPlanoContas") {
                    this.permissao.visualizarRelatorioMovimentacaoPlanoContas  = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioMovimentacaoPlanoContas      = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioMovimentacaoPlanoContas     = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioMovimentacaoPlanoContas     = funcionalidade[j].excluir;
                }
                
                if (funcionalidadeNome == "relatorioLancamentoFinanceiro") {
                    this.permissao.visualizarRelatorioLancamentoFinanceiro = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioLancamentoFinanceiro = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioLancamentoFinanceiro = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioLancamentoFinanceiro = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "relatorioConciliacao") {
                    this.permissao.visualizarRelatorioConciliacao = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioConciliacao = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioConciliacao = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioConciliacao = funcionalidade[j].excluir;
                }
                if (funcionalidadeNome == "relatorioProdutoEntradaSaida") {
                    this.permissao.visualizarRelatorioProdutoEntradaSaida = funcionalidade[j].visualizar;
                    this.permissao.salvarRelatorioProdutoEntradaSaida = funcionalidade[j].incluir;
                    this.permissao.alterarRelatorioProdutoEntradaSaida = funcionalidade[j].alterar;
                    this.permissao.excluirRelatorioProdutoEntradaSaida = funcionalidade[j].excluir;
                }
                // -------------------------------------------- Configuracoes ----------------------------------------------//
                if (funcionalidadeNome == "importacaoArquivoXLS") {
                    this.permissao.visualizarImportacaoXLS = funcionalidade[j].visualizar;
                    this.permissao.salvarImportacaoXLS = funcionalidade[j].incluir;
                    this.permissao.alterarImportacaoXLS = funcionalidade[j].alterar;
                    this.permissao.excluirImportacaoXLS = funcionalidade[j].excluir;
                }

                if (funcionalidadeNome == "setup") {
                    this.permissao.visualizarSetup = funcionalidade[j].visualizar;
                    this.permissao.salvarSetup = funcionalidade[j].incluir;
                    this.permissao.alterarSetup = funcionalidade[j].alterar;
                    this.permissao.excluirSetup = funcionalidade[j].excluir;
                }

            }
        }
        this.isPermissao = true;
    }

    get getPermissoes() {
        return this.permissao;
    }


    get getPlus() {
        return false;
    }

    get getModulos() {
        return this.modulos;
    }



    get getUsuarioLogado() {
        if (this.usuarioLogado.caminhoImagem == null) {
            this.usuarioLogado.caminhoImagem = './assets/img/logo-usuario.png';
        }
        return this.usuarioLogado;
    }

    get getPermissaoSuccess() {
        return this.isPermissao;
    }

    get getDiasLicenca(){
        return this.qtdeDiasLicencaSistema;
    }

    public setRepresentante() {
        if (this.usuarioLogado.idRepresentante > 0 && this.usuarioLogado.adminRepresentante == true) {
            this.isRepresentante = true;
        }
    }

    public blockedGlobalsVariables() {
        if (this.getPermissaoSuccess) {
            let res = false;
            //DADOS DE PERMISSÃO DO USUÁRIO
            this.isPermissao = false;
            this.usuarioLogado.token = undefined;
            this.model = this.usuarioLogado.permissao;
            let modulo: Array<Modulo> = this.model.modulo;
            for (var i = 0; i < modulo.length; i++) {
                let funcionalidade: Array<Funcionalidade> = modulo[i].funcionalidade;
                for (var j = 0; j < funcionalidade.length; j++) {
                    let funcionalidadeNome: String = funcionalidade[j].descricao;

              // -----------------------------------------   Administrativo -------------------------------------------//
                    if (funcionalidadeNome == "dashboard") {
                      this.permissao.visualizarDashboard = res;
                      this.permissao.alterarDashboard = res;
                      this.permissao.salvarDashboard = res;
                      this.permissao.excluirDashboard = res;

                    }

                    if (funcionalidadeNome == "loja") {
                        this.permissao.visualizarLoja = res;
                        this.permissao.alterarLoja = res;
                        this.permissao.salvarLoja = res;
                        this.permissao.excluirLoja = res;

                    }

                    if (funcionalidadeNome == "colaborador") {
                        this.permissao.visualizarColaborador = res;
                        this.permissao.alterarColaborador = res;
                        this.permissao.salvarColaborador = res;
                        this.permissao.excluirColaborador = res;
                    }

                    if (funcionalidadeNome == "favorecido") {
                        this.permissao.visualizarFavorecido = res;
                        this.permissao.alterarFavorecido = res;
                        this.permissao.salvarFavorecido = res;
                        this.permissao.excluirFavorecido = res;
                    }

                    if (funcionalidadeNome == "faixaRenda") {
                        this.permissao.visualizarFaixaRenda = res;
                        this.permissao.salvarFaixaRenda = res;
                        this.permissao.alterarFaixaRenda = res;
                        this.permissao.excluirFaixaRenda = res;
                    }

                    if (funcionalidadeNome == "transportadora") {
                        this.permissao.visualizarTransportadora = res;
                        this.permissao.salvarTransportadora = res;
                        this.permissao.alterarTransportadora = res;
                        this.permissao.excluirTransportadora = res;
                    }

                    if (funcionalidadeNome == "profissao") {
                        this.permissao.visualizarProfissao = res;
                        this.permissao.salvarProfissao = res;
                        this.permissao.alterarProfissao = res;
                        this.permissao.excluirProfissao = res;
                    }

                    if (funcionalidadeNome == "conceito") {
                        this.permissao.visualizarConceito = res;
                        this.permissao.alterarConceito = res;
                        this.permissao.salvarConceito = res;
                        this.permissao.excluirConceito = res;
                    }

                    if (funcionalidadeNome == "ramoAtividade") {
                        this.permissao.visualizarRamoAtividade = res;
                        this.permissao.salvarRamoAtividade = res;
                        this.permissao.alterarRamoAtividade = res;
                        this.permissao.excluirRamoAtividade = res;
                    }


                    if (funcionalidadeNome == "tipoWeb") {
                        this.permissao.visualizarTipoWeb = res;
                        this.permissao.salvarTipoWeb = res;
                        this.permissao.alterarTipoWeb = res;
                        this.permissao.excluirTipoWeb = res;
                    }


                    if (funcionalidadeNome == "tipoTelefone") {
                        this.permissao.visualizarTipoTelefone = res;
                        this.permissao.salvarTipoTelefone = res;
                        this.permissao.alterarTipoTelefone = res;
                        this.permissao.excluirTipoTelefone = res;
                    }

                    if (funcionalidadeNome == "situacaoCliente") {
                        this.permissao.visualizarSituacaoCliente = res;
                        this.permissao.salvarSituacaoCliente = res;
                        this.permissao.alterarSituacaoCliente = res;
                        this.permissao.excluirSituacaoCliente = res;
                    }

                    if (funcionalidadeNome == "credenciadoraCartao") {
                        this.permissao.visualizarCredenciadoraCartao = res;
                        this.permissao.salvarCredenciadoraCartao = res;
                        this.permissao.alterarCredenciadoraCartao = res;
                        this.permissao.excluirCredenciadoraCartao = res;
                    }

                    if (funcionalidadeNome == "bandeiraCartao") {
                        this.permissao.visualizarBandeiraCartao = res;
                        this.permissao.salvarBandeiraCartao = res;
                        this.permissao.alterarBandeiraCartao = res;
                        this.permissao.excluirBandeiraCartao = res;
                    }



                    // ------------------------------------------Estoque ----------------------------------------------------//

                    if (funcionalidadeNome == "categoria") {
                        this.permissao.visualizarCategoria = res;
                        this.permissao.salvarCategoria = res;
                        this.permissao.alterarCategoria = res;
                        this.permissao.excluirCategoria = res;
                    }

                    if (funcionalidadeNome == "marca") {
                        this.permissao.visualizarMarca = res;
                        this.permissao.salvarMarca = res;
                        this.permissao.alterarMarca = res;
                        this.permissao.excluirMarca = res;
                    }

                    if (funcionalidadeNome == "unidade") {
                        this.permissao.visualizarUnidade = res;
                        this.permissao.salvarUnidade = res;
                        this.permissao.alterarUnidade = res;
                        this.permissao.excluirUnidade = res;
                    }

                    if (funcionalidadeNome == "custoOperacional") {
                        this.permissao.visualizarCustoOperacional = res;
                        this.permissao.salvarCustoOperacional = res;
                        this.permissao.alterarCustoOperacional = res;
                        this.permissao.excluirCustoOperacional = res;
                    }

                    if (funcionalidadeNome == "listaPreco") {
                        this.permissao.visualizarListaPreco = res;
                        this.permissao.salvarListaPreco = res;
                        this.permissao.alterarListaPreco = res;
                        this.permissao.excluirListaPreco = res;
                    }

                    if (funcionalidadeNome == "produto") {
                        this.permissao.visualizarProduto = res;
                        this.permissao.salvarProduto = res;
                        this.permissao.alterarProduto = res;
                        this.permissao.excluirProduto = res;
                    }

                    if (funcionalidadeNome == "servico") {
                        this.permissao.visualizarServico = res;
                        this.permissao.salvarServico = res;
                        this.permissao.alterarServico = res;
                        this.permissao.excluirServico = res;
                    }

                    if (funcionalidadeNome == "atualizaPreco") {
                        this.permissao.visualizarAtualizaPreco = res;
                        this.permissao.salvarAtualizaPreco = res;
                        this.permissao.alterarAtualizaPreco = res;
                        this.permissao.excluirAtualizaPreco = res;
                    }

                    if (funcionalidadeNome == "gerarBalanco") {
                        this.permissao.visualizarGerarBalanco = res;
                        this.permissao.salvarGerarBalanco = res;
                        this.permissao.alterarGerarBalanco = res;
                        this.permissao.excluirGerarBalanco = res;
                    }

                    if (funcionalidadeNome == "transferenciaLojas") {
                        this.permissao.visualizarTransferenciaLojas = res;
                        this.permissao.salvarTransferenciaLojas = res;
                        this.permissao.alterarTransferenciaLojas = res;
                        this.permissao.excluirTransferenciaLojas = res;
                    }

                    if (funcionalidadeNome == "confirmarTransferencia") {
                        this.permissao.visualizarConfirmarTransferencia = res;
                        this.permissao.salvarConfirmarTransferencia = res;
                        this.permissao.alterarConfirmarTransferencia = res;
                        this.permissao.excluirConfirmarTransferencia = res;
                    }

                    // -----------------------------------------   Vendas  -------------------------------------------//
                    if (funcionalidadeNome == "pedido") {
                        this.permissao.visualizarPedido = res;
                        this.permissao.salvarPedido = res;
                        this.permissao.alterarPedido = res;
                        this.permissao.excluirPedido = res;
                    }

                    if (funcionalidadeNome == "pdv") {
                        this.permissao.visualizarPDV = res;
                        this.permissao.salvarPDV = res;
                        this.permissao.alterarPDV = res;
                        this.permissao.excluirPDV = res;
                    }

                    if (funcionalidadeNome == "excluirItemPDV") {
                        this.permissao.visualizarExcluirItemPDV = res;
                        this.permissao.salvarExcluirItemPDV = res;
                        this.permissao.alterarExcluirItemPDV = res;
                        this.permissao.excluirExcluirItemPDV = res;
                    }

                    if (funcionalidadeNome == "cancelamentoPedido") {
                        this.permissao.visualizarCancelamentoPedido = res;
                        this.permissao.salvarCancelamentoPedido = res;
                        this.permissao.alterarCancelamentoPedido = res;
                        this.permissao.excluirCancelamentoPedido = res;
                    }

                    if (funcionalidadeNome == "grupoDesconto") {
                        this.permissao.visualizarGrupoDesconto = res;
                        this.permissao.salvarGrupoDesconto = res;
                        this.permissao.alterarGrupoDesconto = res;
                        this.permissao.excluirGrupoDesconto = res;
                    }

                    if (funcionalidadeNome == "comissaoDesconto") {
                        this.permissao.visualizarComissaoDesconto = res;
                        this.permissao.salvarComissaoDesconto = res;
                        this.permissao.alterarComissaoDesconto = res;
                        this.permissao.excluirComissaoDesconto = res;
                    }

                    if (funcionalidadeNome == "tabelaFaturamento") {
                        this.permissao.visualizarTabelaFaturamento = res;
                        this.permissao.salvarTabelaFaturamento = res;
                        this.permissao.alterarTabelaFaturamento = res;
                        this.permissao.excluirTabelaFaturamento = res;
                    }

                    // -----------------------------------------   Compras  -------------------------------------------//
                    if (funcionalidadeNome == "pedidoCompra") {
                        this.permissao.visualizarPedidoCompra = res;
                        this.permissao.salvarPedidoCompra = res;
                        this.permissao.alterarPedidoCompra = res;
                        this.permissao.excluirPedidoCompra = res;
                    }

                    if (funcionalidadeNome == "cancelamentoCompra") {
                        this.permissao.visualizarCancelamentoPedidoCompra = res;
                        this.permissao.salvarCancelamentoPedidoCompra = res;
                        this.permissao.alterarCancelamentoPedidoCompra = res;
                        this.permissao.excluirCancelamentoPedidoCompra = res;
                    }

                    if (funcionalidadeNome == "cancelamentoEntrada") {
                        this.permissao.visualizarCancelamentoEntrada = res;
                        this.permissao.salvarCancelamentoEntrada = res;
                        this.permissao.alterarCancelamentoEntrada = res;
                        this.permissao.excluirCancelamentoEntrada = res;
                    }

                    if (funcionalidadeNome == "entradaCompra") {
                        this.permissao.visualizarEntradaCompra = res;
                        this.permissao.salvarEntradaCompra = res;
                        this.permissao.alterarEntradaCompra = res;
                        this.permissao.excluirEntradaCompra = res;
                    }

                    if (funcionalidadeNome == "importacaoXML") {
                        this.permissao.visualizarImportacaoCompraXML = res;
                        this.permissao.salvarImportacaoCompraXML = res;
                        this.permissao.alterarImportacaoCompraXML = res;
                        this.permissao.excluirImportacaoCompraXML = res;
                    }

                    if (funcionalidadeNome == "sugestaoCompra") {
                        this.permissao.visualizarSugestaoCompraXML = res;
                        this.permissao.salvarSugestaoCompraXML = res;
                        this.permissao.alterarSugestaoCompraXML = res;
                        this.permissao.excluirSugestaoCompraXML = res;
                    }


                    // -----------------------------------------   Devoluções  -------------------------------------------//

                    if (funcionalidadeNome == "devolucaoCliente") {
                        this.permissao.visualizarDevolucaoCliente = res;
                        this.permissao.salvarDevolucaoCliente = res;
                        this.permissao.alterarDevolucaoCliente = res;
                        this.permissao.excluirDevolucaoCliente = res;
                    }

                    if (funcionalidadeNome == "devolucaoFornecedor") {
                        this.permissao.visualizarDevolucaoFornecedor = res;
                        this.permissao.salvarDevolucaoFornecedor = res;
                        this.permissao.alterarDevolucaoFornecedor = res;
                        this.permissao.excluirDevolucaoFornecedor = res;
                    }

                    // -----------------------------------------   Fiscal  -------------------------------------------//

                    if (funcionalidadeNome == "grupoTributoProduto") {
                        this.permissao.visualizarGrupoTributoProduto = res;
                        this.permissao.salvarGrupoTributoProduto = res;
                        this.permissao.alterarGrupoTributoProduto = res;
                        this.permissao.excluirGrupoTributoProduto = res;
                    }


                    if (funcionalidadeNome == "grupoTributoFavorecido") {
                        this.permissao.visualizarGrupoTributoFavorecido = res;
                        this.permissao.salvarGrupoTributoFavorecido = res;
                        this.permissao.alterarGrupoTributoFavorecido = res;
                        this.permissao.excluirGrupoTributoFavorecido = res;
                    }

                    if (funcionalidadeNome == "ncm") {
                        this.permissao.visualizarNCM = res;
                        this.permissao.salvarNCM = res;
                        this.permissao.alterarNCM = res;
                        this.permissao.excluirNCM = res;
                    }

                    if (funcionalidadeNome == "modeloDocumentoFiscal") {
                        this.permissao.visualizarModeloDocumentoFiscal = res;
                        this.permissao.salvarModeloDocumentoFiscal = res;
                        this.permissao.alterarModeloDocumentoFiscal = res;
                        this.permissao.excluirModeloDocumentoFiscal = res;
                    }

                    if (funcionalidadeNome == "cfop") {
                        this.permissao.visualizarCFOP = res;
                        this.permissao.salvarCFOP = res;
                        this.permissao.alterarCFOP = res;
                        this.permissao.excluirCFOP = res;
                    }

                    if (funcionalidadeNome == "convenio") {
                        this.permissao.visualizarConvenio = res;
                        this.permissao.salvarConvenio = res;
                        this.permissao.alterarConvenio = res;
                        this.permissao.excluirConvenio = res;
                    }

                    if (funcionalidadeNome == "situacaoTributaria") {
                        this.permissao.visualizarSituacaoTributaria = res;
                        this.permissao.salvarSituacaoTributaria = res;
                        this.permissao.alterarSituacaoTributaria = res;
                        this.permissao.excluirSituacaoTributaria = res;
                    }

                    if (funcionalidadeNome == "configuracaoTributo") {
                        this.permissao.visualizarConfiguracaoTributo = res;
                        this.permissao.salvarConfiguracaoTributo = res;
                        this.permissao.alterarConfiguracaoTributo = res;
                        this.permissao.excluirConfiguracaoTributo = res;
                    }

                    if (funcionalidadeNome == "operacao") {
                        this.permissao.visualizarOperacao = res;
                        this.permissao.salvarOperacao = res;
                        this.permissao.alterarOperacao = res;
                        this.permissao.excluirOperacao = res;
                    }


                    if (funcionalidadeNome == "spedFiscal") {
                        this.permissao.visualizarSpedFiscal = res;
                        this.permissao.salvarSpedFiscal = res;
                        this.permissao.alterarSpedFiscal = res;
                        this.permissao.excluirSpedFiscal = res;
                    }

                    if (funcionalidadeNome == "spedPisCofins") {
                        this.permissao.visualizarSpedPisCofins = res;
                        this.permissao.salvarSpedPisCofins = res;
                        this.permissao.alterarSpedPisCofins = res;
                        this.permissao.excluirSpedPisCofins = res;
                    }

                    // ------------------------------------------  Financeiro ----------------------------------------------------//
                    if (funcionalidadeNome == "planoContas") {
                        this.permissao.visualizarPlanoContas = res;
                        this.permissao.salvarPlanoContas = res;
                        this.permissao.alterarPlanoContas = res;
                        this.permissao.excluirPlanoContas = res;
                    }

                    if (funcionalidadeNome == "banco") {
                        this.permissao.visualizarBanco = res;
                        this.permissao.salvarBanco = res;
                        this.permissao.alterarCustoBanco = res;
                        this.permissao.excluirBanco = res;
                    }

                    if (funcionalidadeNome == "contaLancamento") {
                        this.permissao.visualizarContaLancamento = res;
                        this.permissao.salvarContaLancamento = res;
                        this.permissao.alterarContaLancamento = res;
                        this.permissao.excluirContaLancamento = res;
                    }

                    if (funcionalidadeNome == "instrucaoBancaria") {
                        this.permissao.visualizarInstrucaoBancaria = res;
                        this.permissao.salvarInstrucaoBancaria = res;
                        this.permissao.alterarInstrucaoBancaria = res;
                        this.permissao.excluirInstrucaoBancaria = res;
                    }

                    if (funcionalidadeNome == "retornoBancario") {
                        this.permissao.visualizarRetornoBancario = res;
                        this.permissao.salvarRetornoBancario = res;
                        this.permissao.alterarRetornoBancario = res;
                        this.permissao.excluirRetornoBancario = res;
                    }


                    if (funcionalidadeNome == "motivoDevolucaoCheque") {
                        this.permissao.visualizarMotivoDevolucaoCheque = res;
                        this.permissao.salvarMotivoDevolucaoCheque = res;
                        this.permissao.alterarMotivoDevolucaoCheque = res;
                        this.permissao.excluirMotivoDevolucaoCheque = res;
                    }

                    if (funcionalidadeNome == "prazo") {
                        this.permissao.visualizarPrazo = res;
                        this.permissao.salvarPrazo = res;
                        this.permissao.alterarPrazo = res;
                        this.permissao.excluirPrazo = res;
                    }

                    if (funcionalidadeNome == "formaPagamento") {
                        this.permissao.visualizarFormaPagamento = res;
                        this.permissao.salvarFormaPagamento = res;
                        this.permissao.alterarFormaPagamento = res;
                        this.permissao.excluirFormaPagamento = res;
                    }

                    if (funcionalidadeNome == "lancamentoFinanceiro") {
                        this.permissao.visualizarLancamentoFinanceiro = res;
                        this.permissao.salvarLancamentoFinanceiro = res;
                        this.permissao.alterarLancamentoFinanceiro = res;
                        this.permissao.excluirLancamentoFinanceiro = res;
                    }

                    if (funcionalidadeNome == "estornoLancamentoFinanceiro") {
                        this.permissao.visualizarEstornoLancamentoFinanceiro = res;
                        this.permissao.salvarEstornoLancamentoFinanceiro = res;
                        this.permissao.alterarEstornoLancamentoFinanceiro = res;
                        this.permissao.excluirEstornoLancamentoFinanceiro = res;
                    }

                    if (funcionalidadeNome == "duplicataReceber") {
                        this.permissao.visualizarDuplicataReceber = res;
                        this.permissao.salvarDuplicataReceber = res;
                        this.permissao.alterarDuplicataReceber = res;
                        this.permissao.excluirDuplicataReceber = res;
                    }

                    if (funcionalidadeNome == "baixaParcelaReceber") {
                        this.permissao.visualizarBaixaParcelaReceber = res;
                        this.permissao.salvarBaixaParcelaReceber = res;
                        this.permissao.alterarBaixaParcelaReceber = res;
                        this.permissao.excluirBaixaParcelaReceber = res;
                    }


                    if (funcionalidadeNome == "faturamento") {
                        this.permissao.visualizarFaturamento = res;
                        this.permissao.salvarFaturamento = res;
                        this.permissao.alterarFaturamento = res;
                        this.permissao.excluirFaturamento = res;
                    }


                    if (funcionalidadeNome == "duplicataPagar") {
                        this.permissao.visualizarDuplicataPagar = res;
                        this.permissao.salvarDuplicataPagar = res;
                        this.permissao.alterarDuplicataPagar = res;
                        this.permissao.excluirDuplicataPagar = res;
                    }

                    if (funcionalidadeNome == "baixaParcelaPagar") {
                        this.permissao.visualizarBaixaParcelaPagar = res;
                        this.permissao.salvarBaixaParcelaPagar = res;
                        this.permissao.alterarBaixaParcelaPagar = res;
                        this.permissao.excluirBaixaParcelaPagar = res;
                    }

                    if (funcionalidadeNome == "fechamentoConta") {
                        this.permissao.visualizarFechamentoConta = res;
                        this.permissao.salvarFechamentoConta = res;
                        this.permissao.alterarFechamentoConta = res;
                        this.permissao.excluirFechamentoConta = res;
                    }


                    // ------------------------------------------  Segurança ----------------------------------------------------//
                    if (funcionalidadeNome == "permissoes") {
                        this.permissao.visualizarPermissao = res;
                        this.permissao.salvarPermissao = res;
                        this.permissao.alterarPermissao = res;
                        this.permissao.excluirPermissao = res;
                    }

                    if (funcionalidadeNome == "usuario") {
                        this.permissao.visualizarUsuario = res;
                        this.permissao.salvarUsuario = res;
                        this.permissao.alterarUsuario = res;
                        this.permissao.excluirUsuario = res;
                    }

                    if (funcionalidadeNome == "grupoLoja") {
                        this.permissao.visualizarGrupoLoja = res;
                        this.permissao.salvarGrupoLoja = res;
                        this.permissao.alterarGrupoLoja = res;
                        this.permissao.excluirGrupoLoja = res;
                    }

                    // ------------------------------------------- Equipamento ----------------------------------------------//
                    if (funcionalidadeNome == "telaEquipamento") {
                        this.permissao.visualizarEquipamento = res;
                        this.permissao.salvarEquipamento = res;
                        this.permissao.alterarEquipamento = res;
                        this.permissao.excluirEquipamento = res;
                    }

                    if (funcionalidadeNome == "modeloEquipamento") {
                        this.permissao.visualizarModeloEquipamento = res;
                        this.permissao.salvarModeloEquipamento = res;
                        this.permissao.alterarModeloEquipamento = res;
                        this.permissao.excluirModeloEquipamento = res;
                    }

                    // ------------------------------------------- Veiculos ----------------------------------------------//
                    if (funcionalidadeNome == "telaVeiculo") {
                        this.permissao.visualizarVeiculo = res;
                        this.permissao.salvarVeiculo = res;
                        this.permissao.alterarVeiculo = res;
                        this.permissao.excluirVeiculo = res;
                    }

                    if (funcionalidadeNome == "fabricanteVeiculo") {
                        this.permissao.visualizarFabricanteVeiculo = res;
                        this.permissao.salvarFabricanteVeiculo = res;
                        this.permissao.alterarFabricanteVeiculo = res;
                        this.permissao.excluirFabricanteVeiculo = res;
                    }

                    if (funcionalidadeNome == "modeloVeiculo") {
                        this.permissao.visualizarModeloVeiculo = res;
                        this.permissao.salvarModeloVeiculo = res;
                        this.permissao.alterarModeloVeiculo = res;
                        this.permissao.excluirModeloVeiculo = res;
                    }

                    // ------------------------------------------- Relatorios ----------------------------------------------//
                    if (funcionalidadeNome == "relatorioCliente") {
                        this.permissao.visualizarRelatorioCliente = res;
                        this.permissao.salvarRelatorioCliente = res;
                        this.permissao.alterarRelatorioCliente = res;
                        this.permissao.excluirRelatorioCliente = res;
                    }

                    if (funcionalidadeNome == "relatorioProduto") {
                        this.permissao.visualizarRelatorioProduto = res;
                        this.permissao.salvarRelatorioProduto = res;
                        this.permissao.alterarRelatorioProduto = res;
                        this.permissao.excluirRelatorioProduto = res;
                    }

                    if (funcionalidadeNome == "relatorioVendas") {
                        this.permissao.visualizarRelatorioVendas = res;
                        this.permissao.salvarRelatorioVendas = res;
                        this.permissao.alterarRelatorioVendas = res;
                        this.permissao.excluirRelatorioVendas = res;
                    }

                    if (funcionalidadeNome == "minutaDespacho") {
                        this.permissao.visualizarMinutaDespacho = res;
                        this.permissao.salvarMinutaDespacho = res;
                        this.permissao.alterarMinutaDespacho = res;
                        this.permissao.excluirMinutaDespacho = res;
                    }

                    if (funcionalidadeNome == "relatorioResumoVendas") {
                        this.permissao.visualizarRelatorioResumoVendas = res;
                        this.permissao.salvarRelatorioResumoVendas = res;
                        this.permissao.alterarRelatorioResumoVendas = res;
                        this.permissao.excluirRelatorioResumoVendas = res;
                    }

                    if (funcionalidadeNome == "relatorioCompras") {
                        this.permissao.visualizarRelatorioCompras = res;
                        this.permissao.salvarRelatorioCompras = res;
                        this.permissao.alterarRelatorioCompras = res;
                        this.permissao.excluirRelatorioCompras = res;
                    }

                    if (funcionalidadeNome == "relatorioAuditoriaEstoque") {
                        this.permissao.visualizarRelatorioAuditoriaEstoque = res;
                        this.permissao.salvarRelatorioAuditoriaEstoque = res;
                        this.permissao.alterarRelatorioAuditoriaEstoque = res;
                        this.permissao.excluirRelatorioAuditoriaEstoque = res;
                    }

                    if (funcionalidadeNome == "relatorioEtiqueta") {
                        this.permissao.visualizarRelatorioEtiqueta = res;
                        this.permissao.salvarRelatorioEtiqueta = res;
                        this.permissao.alterarRelatorioEtiqueta = res;
                        this.permissao.excluirRelatorioEtiqueta = res;
                    }

                    if (funcionalidadeNome == "relatorioDuplicatasReceber") {
                        this.permissao.visualizarRelatorioDuplicatasReceber = res;
                        this.permissao.salvarRelatorioDuplicatasReceber = res;
                        this.permissao.alterarRelatorioDuplicatasReceber = res;
                        this.permissao.excluirRelatorioDuplicatasReceber = res;
                    }

                    if (funcionalidadeNome == "relatorioDuplicatasPagar") {
                        this.permissao.visualizarRelatorioDuplicatasPagar = res;
                        this.permissao.salvarRelatorioDuplicatasPagar = res;
                        this.permissao.alterarRelatorioDuplicatasPagar = res;
                        this.permissao.excluirRelatorioDuplicatasPagar = res;
                    }

                    if (funcionalidadeNome == "relatorioPlanoContas") {
                        this.permissao.visualizarRelatorioPlanoContas = res;
                        this.permissao.salvarRelatorioPlanoContas = res;
                        this.permissao.alterarRelatorioPlanoContas = res;
                        this.permissao.excluirRelatorioPlanoContas = res;
                    }

                    
                    if (funcionalidadeNome == "relatorioMovimentacaoPlanoContas") {
                        this.permissao.visualizarRelatorioMovimentacaoPlanoContas  = res;
                        this.permissao.salvarRelatorioMovimentacaoPlanoContas      = res;
                        this.permissao.alterarRelatorioMovimentacaoPlanoContas     = res;
                        this.permissao.excluirRelatorioMovimentacaoPlanoContas     = res;
                    }


                    if (funcionalidadeNome == "relatorioConciliacao") {
                        this.permissao.visualizarRelatorioConciliacao = res;
                        this.permissao.salvarRelatorioConciliacao = res;
                        this.permissao.alterarRelatorioConciliacao = res;
                        this.permissao.excluirRelatorioConciliacao = res;
                    }

                    if (funcionalidadeNome == "relatorioProdutoEntradaSaida") {
                        this.permissao.visualizarRelatorioProdutoEntradaSaida = res;
                        this.permissao.salvarRelatorioProdutoEntradaSaida = res;
                        this.permissao.alterarRelatorioProdutoEntradaSaida = res;
                        this.permissao.excluirRelatorioProdutoEntradaSaida = res;
                    }


                    // ------------------------------------------- Configuracoes ----------------------------------------------//
                    if (funcionalidadeNome == "importacaoArquivoXLS") {
                        this.permissao.visualizarImportacaoXLS = res;
                        this.permissao.salvarImportacaoXLS = res;
                        this.permissao.alterarImportacaoXLS = res;
                        this.permissao.excluirImportacaoXLS = res;
                    }

                    if (funcionalidadeNome == "setup") {
                        this.permissao.visualizarSetup = res;
                        this.permissao.salvarSetup = res;
                        this.permissao.alterarSetup = res;
                        this.permissao.excluirSetup = res;
                    }

                }
            }

        }
    }

    public setInformacoesSetup(res) {
        this.setup = res;
        localStorage.setItem("tipoPesquisa", this.setup.tipoPesquisa)
    }


    get setupModel() {
        return this.setup;
    }

}
